import type { Config } from './types'


let config: Config

if (__SERVER__) {
  config = require('./server').default
}
else {
  config = require('./client').default
}


export default config
