import type { Config } from './types'


if (window.__CONFIG__?.cityName) {
  window.__CONFIG__.cityName = window.__CONFIG__.cityName.replace(/_/g, ' ')
}

window.__CONFIG__.isLegacyAuth = !window.__CONFIG__.storage.includes('/platform-prod/nn')


export default (window.__CONFIG__) as Config
